import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private isAlertActive = false;

  constructor(
    public alertController: AlertController,
    private translator: TranslateService) {
  }

  /**
   * prepares a popup that can just be confirmed based on error code, translations prepared on each start
   *
   * @param error
   */
  public showError(error) {
    let connectionHeader: string;
    let connectionText: string;
    let credentialsHeader: string;
    let credentialsText: string;
    let gatewayHeader: string;
    let gatewayText: string;
    let unauthorizedHeader: string;
    let unauthorizedText: string;
    let notFoundHeader: string;
    let notFoundText: string;
    let unknownHeader: string;
    let unknownText: string;
    let confirm: string;
    let cancel: string;
    this.translator.get('alert.connectionHeader').subscribe((result: string) => connectionHeader = result);
    this.translator.get('alert.connectionText').subscribe((result: string) => connectionText = result);
    this.translator.get('alert.credentialsHeader').subscribe((result: string) => credentialsHeader = result);
    this.translator.get('alert.credentialsText').subscribe((result: string) => credentialsText = result);
    this.translator.get('alert.gatewayHeader').subscribe((result: string) => gatewayHeader = result);
    this.translator.get('alert.gatewayText').subscribe((result: string) => gatewayText = result);
    this.translator.get('alert.unauthorizedHeader').subscribe((result: string) => unauthorizedHeader = result);
    this.translator.get('alert.unauthorizedText').subscribe((result: string) => unauthorizedText = result);
    this.translator.get('alert.notFoundHeader').subscribe((result: string) => notFoundHeader = result);
    this.translator.get('alert.notFoundText').subscribe((result: string) => notFoundText = result);
    this.translator.get('alert.unknownHeader').subscribe((result: string) => unknownHeader = result);
    this.translator.get('alert.unknownText').subscribe((result: string) => unknownText = result);
    this.translator.get('alert.confirm').subscribe((result: string) => confirm = result);
    this.translator.get('alert.cancel').subscribe((result: string) => cancel = result);

    switch (error.status) {
      case 0:
        this.presentAlertConfirm(
          connectionHeader,
          connectionText,
          confirm);
        console.warn('Api not reached');
        console.log(error);
        break;
      case 400://wrong credentials
        this.presentAlertConfirm(credentialsHeader, credentialsText, confirm);
        console.warn('Wrong credentials');
        console.warn(error.error.message);
        break;
      case 401://unauthorized
        this.presentAlertConfirm(unauthorizedHeader, unauthorizedText, confirm);
        console.warn('Unauthorized');
        console.warn(error.error.message);
        break;
      case 404://not found
        this.presentAlertConfirm(notFoundHeader, notFoundText, confirm);
        console.warn('No Data found');
        console.warn(error.error.message);
        break;
      case 504: // gateway timeout
        this.presentAlertConfirm(gatewayHeader, gatewayText, confirm);
        console.warn(gatewayHeader);
        console.warn(error.error.message);
        break;
      default:
        this.presentAlertConfirm(unknownHeader, unknownText, confirm);
        console.warn('Unknown');
        console.warn(error);
        break;
    }
  }

  /**
   * Alert pop up with given header, text and possible cancel, could be extended for confirm and cancel actions to be passed
   *
   * @param headerText
   * @param text info text
   * @param confirmText text for confirm button
   * @param cancelText text for cancel button, if not given cancel is not displayed
   */
  public async presentAlertConfirm(headerText: string, text: string, confirmText: string, cancelText?: string) {
    if (this.isAlertActive) {
      return;
    }
    this.isAlertActive = true;
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: headerText,
      message: text
    });

    if (cancelText) {
      alert.buttons = [
        {
          text: cancelText,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (param) => {
            this.isAlertActive = false;
            console.log('Confirm Cancel: param: ' + param);
          }
        }, {
          text: confirmText,
          handler: () => {
            this.isAlertActive = false;
            console.log('Confirm Okay');
          }
        }
      ];
    } else {
      alert.buttons = [
        {
          text: confirmText,
          handler: () => {
            this.isAlertActive = false;
            console.log('Confirm Okay');
          }
        }
      ];
    }

    await alert.present();
  }
}
