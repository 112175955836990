import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {VideoDialogComponent} from './video-dialog/video-dialog.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AppWidgetComponent} from './app-widget/app-widget.component';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';

export const createTranslateLoader = (http) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    VideoDialogComponent,
    AppWidgetComponent
  ],
  imports: [
    FlexModule,
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatGridListModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule
  ],
  providers:
    [{
      provide: 'video-dialog',
      useValue: VideoDialogComponent,
    },
      {provide: MatDialogRef, useValue: {}},
      {provide: MAT_DIALOG_DATA, useValue: {}}
    ],
  exports: [
    VideoDialogComponent,
    AppWidgetComponent
  ]
})
export class GadgetsComponentsModule {
}
