import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {ChartType} from 'angular-google-charts';
import {Recruiting} from '../../../../interfaces/recruiting';
import {GlobalService} from '../../../../services/global.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RecruitingDetailsComponent} from '../../bar-charts/recruiting-details/recruiting-details.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-recruiting',
  templateUrl: './recruiting.component.html',
  styleUrls: ['./recruiting.component.scss'],
})
export class RecruitingComponent implements OnInit, OnChanges {
  @Input() recruiting: Recruiting[];
  @Input() title: string;

  type = ChartType.PieChart;
  data = [];
  columnNames = ['Recruiting', ' '];
  options = {};
  public spinning = true;
  private globalVar;
  private dialogRef: MatDialogRef<RecruitingDetailsComponent>;

  constructor(private global: GlobalService,
              public dialog: MatDialog,
              private translator: TranslateService) {
    this.globalVar = global;
    this.options = {
      legend: {position: 'none'},
      axisFontSize: 0,
      slices: {
        0: {color: this.globalVar.rangerBlue},
        1: {color: this.globalVar.rangerGrey},
        2: {color: 'transparent'}
      },
      pieSliceText: 'label',
      chartArea: {top: '50', width: '100%', height: '100%'},
      pieStartAngle: 270,
      pieHole: 0.4,
      tooltip: {trigger: 'none'}
    };
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   * updates the chart and sets the design. if value is higher than max, boarders are adjusted to keep the look
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.recruiting && changes.recruiting.currentValue) {
      const week = this.recruiting.find(item => item.period === 'Week');
      const maxValue = 8;
      if (week.recruited <= maxValue) { //used to always display half circle, even if greater then maxValue
        this.data = ([['' + week.recruited, week.recruited], [' ', maxValue - week.recruited], [' ', maxValue]]);
      } else {
        this.data = ([['' + week.recruited, week.recruited], [' ', 0], [' ', week.recruited]]);
      }
      this.spinning = false;
    }
  }

  /**
   * opens a modal dialog to display more data in a bar chart
   *
   * @param recruiting more statistic data
   */
  showDetails(recruiting) {
    if (!recruiting) return;
    let titleTranslated;
    this.translator.get('dashboard.header.recruitingDetails').subscribe((result: string) => titleTranslated = result);

    const innerWidth = window.innerWidth;
    const dialogConfig = this.globalVar.prepareDialog(innerWidth);
    dialogConfig.data = {
      recruiting,
      title: titleTranslated,
      height: dialogConfig.height
    };
    this.dialogRef = this.dialog.open(RecruitingDetailsComponent, dialogConfig);
  }
}
