import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() codes: string[];
  @Input() isHidden: boolean;

  constructor(
    private translate: TranslateService) {
  }

  ngOnInit() {
  }

  /**
   * called when one of the languages in the side menu is clicked
   * changes the default language
   *
   * @param lang language code for the selected language
   */
  onTranslateClick(lang) {
    localStorage.setItem('language', lang);
    this.translate.setDefaultLang(lang);
  }

}
