import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {GlobalService} from '../../../services/global.service';


@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent implements OnInit {
  videoUrl;
  safeUrl: any;
  private globalVar;
  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;

  /**
   * edits the url if from YouTube for embedded videos, and also prepares it for browser security settings
   *
   * @param global
   * @param sanitizer
   * @param dialogRef
   * @param data
   */
  constructor(
    private global: GlobalService,
    private sanitizer: DomSanitizer,
    @Optional() public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.globalVar = global;
    this.videoUrl = (data.videoUrl.replace('watch?v=', 'embed/')) + '?&autoplay=1';
    // eslint-disable-next-line no-underscore-dangle
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);

    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(() => {
      const innerWidth = window.innerWidth;
      const dialogConfig = this.globalVar.prepareDialog(innerWidth);
      const screen = document.getElementById('cdk-overlay-0');
      screen.style.width = dialogConfig.width;
      screen.style.height = dialogConfig.height;
    });
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close('Play Video Closed');
  }
}
