import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-recruiting-form',
  templateUrl: './recruiting-form.component.html',
  styleUrls: ['./recruiting-form.component.scss'],
})
export class RecruitingFormComponent implements OnInit {
  @Input() title: string;

  constructor() { }

  ngOnInit() {}

}
