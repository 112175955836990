import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TierComponent} from './user/tier/tier.component';
import {UserComponent} from './user/user.component';
import {RecruitingComponent} from './pie-charts/recruiting/recruiting.component';
import {MessagesComponent} from './messages/messages.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {GoogleChartsModule} from 'angular-google-charts';
import {DailyGoalComponent} from './bar-charts/daily-goal/daily-goal.component';
import {GoalBarComponent} from './bar-charts/goal-bar/goal-bar.component';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {DualSinglePieComponent} from './pie-charts/dual-single-pie/dual-single-pie.component';
import {EnergyPieComponent} from './pie-charts/energy-pie/energy-pie.component';
import {RecruitingFormComponent} from './recruiting-form/recruiting-form.component';
import {UserInfoComponent} from './user/user-info/user-info.component';
import {FlexModule} from '@angular/flex-layout';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {BarComponent} from './bar-charts/bar/bar.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AnomaliesComponent} from './anomalies/anomalies.component';
import {RecruitingDetailsComponent} from './bar-charts/recruiting-details/recruiting-details.component';
import {GadgetsComponentsModule} from '../gadgets/gadgets.components.module';
import {DualSingleDetailsComponent} from './bar-charts/dual-single-details/dual-single-details.component';
import {EnergyDetailsComponent} from './bar-charts/energy-details/energy-details.component';
import {BellBarComponent} from './bar-charts/bell-bar/bell-bar.component';


export const createTranslateLoader = (http) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    TierComponent,
    DualSinglePieComponent,
    EnergyPieComponent,
    UserComponent,
    RecruitingComponent,
    RecruitingFormComponent,
    MessagesComponent,
    DailyGoalComponent,
    GoalBarComponent,
    UserInfoComponent,
    BarComponent,
    AnomaliesComponent,
    RecruitingDetailsComponent,
    DualSingleDetailsComponent,
    EnergyDetailsComponent,
    BellBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GoogleChartsModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatButtonModule,
    FlexModule,
    MatProgressBarModule,
    MatDialogModule,
    GadgetsComponentsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    })
  ],
  providers:
    [
      {provide: MatDialogRef, useValue: {}},
      {provide: MAT_DIALOG_DATA, useValue: {}}
    ],
  exports: [
    TierComponent,
    DualSinglePieComponent,
    EnergyPieComponent,
    UserComponent,
    RecruitingComponent,
    RecruitingFormComponent,
    MessagesComponent,
    DailyGoalComponent,
    GoalBarComponent,
    UserInfoComponent,
    BarComponent,
    AnomaliesComponent,
    RecruitingDetailsComponent,
    DualSingleDetailsComponent,
    EnergyDetailsComponent,
    BellBarComponent
  ]
})
export class ChartsComponentsModule {
}
