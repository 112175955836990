import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-bell-bar', templateUrl: './bell-bar.component.html', styleUrls: ['./bell-bar.component.scss'],
})
export class BellBarComponent implements OnInit, OnChanges {
  @Input() bells: number [];
  @Input() barLength: number;
  @Input() currentValue: string;

  public largeScreen: boolean;
  public bellStyle: { style: string; value: any }[];
  resizeObservable: Observable<Event>;
  resizeSubscription: Subscription;

  constructor() {
    let input = document.getElementById('main');
    this.largeScreen = input.clientWidth > 549;
    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(() => {
      input = document.getElementById('main');
      this.largeScreen = input.clientWidth > 549;
    });
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   * sets the position for each bell given
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.bells && changes.bells.currentValue) {
      this.bellStyle = [];
      this.bells.forEach(bell => {
        this.bellStyle.push({
          style: '--label-value:' + Math.min(100, (100 - Math.floor(bell / this.barLength * 100))) + '%;', value: bell
        });
      });
    }
  }
}
