import {Injectable} from '@angular/core';
import {NavController} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
//services
import {GlobalService} from './global.service';
import {ApiRequestService} from './api-request.service';
import {AlertService} from './alert.service';
import {Token} from '../interfaces/token';
import {UserRank} from '../enums/user-rank';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private globalVar: GlobalService;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private navCtrl: NavController,
    private apiRequestService: ApiRequestService,
    private alert: AlertService
  ) {
    this.globalVar = this.globalService;
  }


  /**
   * requests the bearer token using credentials. stores it global and redirects user
   *
   * @param postData
   */
  login(postData) {
    //http post returns token
    console.log('login request started');
    this.apiRequestService.apiPostRequest('User/authenticate', postData).subscribe(
      (data) => {
        console.log('login answer received started');
        //token stored in local storage
        localStorage.setItem('currentUser', (data as { token: string }).token);
        console.log('login token handled');
        const group = (data as { group: string }).group;
        console.log('login group handled');
        localStorage.setItem('userRank', '' + UserRank.member);
        console.log('login rank handled');
        this.globalVar.spinning = false;

        console.log('login redirect started');
        this.navCtrl.navigateForward('/main');
      },
      (error) => {
        this.alert.showError(error);
        this.globalVar.spinning = false;
      }
    );
  }

  /**
   * remove user from local storage to log user out and redirect
   */
  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('logo');
    localStorage.removeItem('userRank');
    this.navCtrl.navigateRoot('');
  }

  /**
   * checks if the user is logged in and if token is not expired, then redirect to main, otherwise redirect to login and clear storage
   */
  validationChecker() {
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/dot-notation
    const currentPage = this.navCtrl['location']._platformLocation.location.pathname;
    if (this.globalVar.currentUser) {
      const token = jwt_decode(this.globalVar.currentUser) as Token;
      if (!(token.exp < Date.now() / 1000)) {
        //user token exists and is not expired, then redirect to dashboard if on login page
        if (currentPage === '/login') {
          this.navCtrl.navigateForward('/main');
        }
      } else {
        localStorage.clear();
        this.navCtrl.navigateRoot('');
      }
    } else {
      if (currentPage !== '/login') {
        localStorage.clear();
        this.navCtrl.navigateForward('/login');
      }
    }
  }
}
