import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChartType} from 'angular-google-charts';
import {GlobalService} from '../../../../services/global.service';
import {Calendar} from '../../../../enums/calendar';

@Component({
  selector: 'app-recruiting-details',
  templateUrl: './recruiting-details.component.html',
  styleUrls: ['./recruiting-details.component.scss'],
})
export class RecruitingDetailsComponent implements OnInit {
  type = ChartType.Bar;
  chartData = [];
  columnNames = [' ', ' '];
  options = {};
  private globalVar;

  /**
   * pollutes the bar chart with the given data and sets the style. translated on each reopening
   *
   * @param dialogRef new popup window
   * @param data data for the chart
   * @param global global methods and variables
   */
  constructor(@Optional() public dialogRef: MatDialogRef<RecruitingDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private global: GlobalService) {
    this.globalVar = global;

    const calendar = this.globalVar.getCalendarTranslations();
    data.recruiting.forEach(item => {
      if (item.period !== 'Day' && item.period !== 'Week') {
        this.chartData.push([calendar[Calendar[item.period.toLowerCase()]], item.recruited]);
      }
    });
    this.options = {
      height: Number(this.data.height.split('p')[0]) - 150,
      legend: {position: 'none'},
      colors: [this.globalVar.rangerBlue],
      axes: {
        y: {
          all: {
            range: {
              max: 11,
              min: 0
            }
          }
        }
      }
    };
  }

  ngOnInit() {
  }

  /**
   * closes the new window
   */
  close() {
    this.dialogRef.close('Recruiting Details Closed');
  }
}
