import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnChanges {
  @Input() url;
  public safeUrl: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  /**
   * edits the url to open the Google Drive embedded with the grid view, list is also possible but not wished
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.url && changes.url.currentValue) {
      let url;
      url = this.url.split('?')[0];
      url = url.replace('https://drive.google.com/drive/folders/', 'https://drive.google.com/embeddedfolderview?id=');
      url += '#grid';
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}
