import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from '../../../interfaces/user-model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @Input() userModel: UserModel;
  @Input() userImage;
  @Input() tierImage;

  constructor() {
  }

  ngOnInit() {
  }
}
