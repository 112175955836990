import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuController, NavController} from '@ionic/angular';
import {fromEvent, Observable, Subscription} from 'rxjs';
//services
import {GlobalService} from './services/global.service';
import {AuthenticationService} from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public languageSelection = false;
  public globalVar;
  public auth;
  public languageCodes: string[];
  resizeObservable: Observable<Event>;
  resizeSubscription: Subscription;
  headerSize;

  constructor(
    @Inject('API_URL') private apiUrl: string,
    @Inject('ENGIEAPP_URL') private engieAppUrl: string,
    @Inject('HERAAPP_URL') private heraAppUrl: string,
    @Inject('HERABACKENDAPP_URL') private heraBackendAppUrl: string,
    @Inject('SMARTAPP_URL') private smartAppUrl: string,
    @Inject('SMARTBACKENDAPP_URL') private smartBackendAppUrl: string,
    @Inject('EDISONAPP_URL') private edisonAppUrl: string,
    @Inject('EDISONBACKENDAPP_URL') private edisonBackendAppUrl: string,
    private menu: MenuController,
    private navCtrl: NavController,
    private translate: TranslateService,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    //Needs to be set once at the start, otherwise the list of languages can´t be retrieved
    //Can´t be set below the normal set part, because the list is needed for that
    this.translate.setDefaultLang('en');

    //gets possible languages
    this.translate.get('languages').subscribe(result => {
      this.languageCodes = Object.keys(result);
      //sets the current language as chosen,browser or default
      const chosenLanguage = localStorage.getItem('language');
      if (chosenLanguage) {
        this.translate.setDefaultLang(chosenLanguage);
      } else {
        const browserLang = this.translate.getBrowserLang();
        if (browserLang && this.languageCodes && this.languageCodes.includes(browserLang)) {
          this.translate.setDefaultLang(browserLang);
        } else {
          this.translate.setDefaultLang('en');
        }
      }
    });

    this.globalVar = this.globalService;
    this.auth = this.authenticationService;

    this.authenticationService.validationChecker();

    //get size of the ranger header, store it and adjust page margin
    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(() => {
      this.globalVar.headerHeight = document.getElementById('globalHeader').clientHeight;
    });
    this.globalVar.api = new URL(this.apiUrl);
    this.globalVar.engieAppUrl = this.engieAppUrl;
    this.globalVar.heraAppUrl = this.heraAppUrl;
    this.globalVar.heraBackendAppUrl = this.heraBackendAppUrl;
    this.globalVar.smartAppUrl = this.smartAppUrl;
    this.globalVar.smartBackendAppUrl = this.smartBackendAppUrl;
    this.globalVar.edisonAppUrl = this.edisonAppUrl;
    this.globalVar.edisonBackendAppUrl = this.edisonBackendAppUrl;
  }

  /**
   * called when the logout in the side menu is clicked
   * calls the authentication service and performs the logout
   */
  onLogout() {
    this.auth.logout();
  }
}
