import {Component, OnInit} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {Apps} from '../../../interfaces/apps';
import {GlobalService} from '../../../services/global.service';
import {Token} from '../../../interfaces/token';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
})
export class AppsComponent implements OnInit {
  public appList: Apps[]=[];
  private globalVar: GlobalService;

  constructor(
    private globalService: GlobalService) {
    this.globalVar = globalService;
  }

  ngOnInit() {
    const token = jwt_decode(this.globalVar.currentUser) as Token;

    //ENGIE
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Engie') || token['role'].includes('Admin') || token['role'].includes('AdminEngie') || token['role'].includes('AdminSalesPartnerEngie')){
      this.appList.push({
          name: 'Engie',
          img: '../../assets/img/apps/engie.png',
          path: this.globalVar.engieAppUrl,
        } as Apps
      );
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Admin') || token['role'].includes('AdminEngie') || token['role'].includes('AdminSalesPartnerEngie')){
      this.appList.push({
          name: 'Engie',
          img: '../../assets/img/apps/engie-verify.png',
          path: this.globalVar.engieAppUrl+'/verify',
        } as Apps
      );
    }

    //HERA
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Hera') || token['role'].includes('Admin') || token['role'].includes('AdminHera') || token['role'].includes('AdminSalesPartnerHera')){
      this.appList.push({
          name: 'Hera',
          img: '../../assets/img/apps/hera.png',
          path: this.globalVar.heraAppUrl,
        } as Apps
      );
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Admin') || token['role'].includes('AdminHera') || token['role'].includes('AdminSalesPartnerHera')){
      this.appList.push({
          name: 'Hera',
          img: '../../assets/img/apps/hera-verify.png',
          path: this.globalVar.heraBackendAppUrl,
        } as Apps
      );
    }

    //SMART ENERGY
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('SmartEnergy') || token['role'].includes('Admin') || token['role'].includes('AdminSmartEnergy') || token['role'].includes('AdminSalesPartnerSmartEnergy')){
      this.appList.push({
          name: 'SmartEnergy',
          img: '../../assets/img/apps/smart.png',
          path: this.globalVar.smartAppUrl,
        } as Apps
      );
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Admin') || token['role'].includes('AdminSmartEnergy') || token['role'].includes('AdminSalesPartnerSmartEnergy')){
      this.appList.push({
          name: 'SmartEnergy',
          img: '../../assets/img/apps/smart-verify.png',
          path: this.globalVar.smartBackendAppUrl,
        } as Apps
      );
    }
    // todo smartenergy-part



    //Edison
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Edison') || token['role'].includes('Admin') || token['role'].includes('AdminEdison') || token['role'].includes('AdminSalesPartnerEdison')){
      this.appList.push({
          name: 'Edison',
          img: '../../assets/img/apps/edison.png',
          path: this.globalVar.edisonAppUrl,
        } as Apps
      );
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Admin') || token['role'].includes('AdminEdison') || token['role'].includes('AdminSalesPartnerEdison')){
      this.appList.push({
          name: 'Edison',
          img: '../../assets/img/apps/edison-verify.png',
          path: this.globalVar.edisonBackendAppUrl,
        } as Apps
      );
    }
  }
}
