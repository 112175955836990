import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Productivity} from '../../../../interfaces/productivity';
import {ChartType} from 'angular-google-charts';
import {GlobalService} from '../../../../services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EnergyDetailsComponent} from '../../bar-charts/energy-details/energy-details.component';

@Component({
  selector: 'app-energy-pie',
  templateUrl: './energy-pie.component.html',
  styleUrls: ['./energy-pie.component.scss'],
})
export class EnergyPieComponent implements OnInit, OnChanges {
  @Input() productivity: Productivity[];
  @Input() title: string;
  type = ChartType.PieChart;
  data = [];
  columnNames = ['Type', 'Sales'];
  options = {};
  public spinning = true;
  private globalVar;
  private dialogRef: MatDialogRef<EnergyDetailsComponent>;

  constructor(private global: GlobalService,
              public dialog: MatDialog,
              private translator: TranslateService) {
    this.globalVar = global;
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   * updates the chart and sets the design. differs if all data is 0
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    let energy;
    let telephony;
    this.translator.get('dashboard.header.energyDetails.energy').subscribe((result: string) => energy = result);
    this.translator.get('dashboard.header.energyDetails.telephony').subscribe((result: string) => telephony = result);
    if (changes.productivity && changes.productivity.currentValue) {
      const day = this.productivity.find(item => item.period === 'Day');
      if (day.telephony === 0 && day.energy === 0) {
        let start;
        this.translator.get('dashboard.pieChart.start').subscribe((result: string) => start = result);
        this.data = ([[start, 1]]);
        this.options = {
          legend: {position:'none'},
          chartArea: {width:'80%',height:'80%'},
          pieSliceText: 'label',
          axisFontSize : 0,
          slices: {
            0: {color: this.globalVar.rangerGrey}
          }
        };
      } else {
        this.data = ([[energy, day.energy], [telephony, day.telephony]]);
        this.options = {
          legend: {
            position: 'right'
          },
          chartArea: {width:'80%',height:'80%'},
          pieSliceText: 'value',
          slices: {
            0: {color: this.globalVar.rangerBlue},
            1: {color: this.globalVar.rangerOrange}
          }
        };
      }
      this.spinning = false;
    }
  }

  /**
   * opens a modal dialog to display more data in a bar chart
   *
   * @param productivity more statistic data
   */
  showDetails(productivity) {
    if (!productivity) return;
    let titleTranslated;
    this.translator.get('dashboard.header.energyDetails.title').subscribe((result: string) => titleTranslated = result);

    const innerWidth = window.innerWidth;
    const dialogConfig = this.globalVar.prepareDialog(innerWidth);
    dialogConfig.data = {
      productivity,
      title: titleTranslated,
      height: dialogConfig.height
    };
    this.dialogRef = this.dialog.open(EnergyDetailsComponent, dialogConfig);
  }
}
