import {Component, Inject, OnInit, Optional} from '@angular/core';
import {ChartType} from 'angular-google-charts';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalService} from '../../../../services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {Calendar} from '../../../../enums/calendar';

@Component({
  selector: 'app-dual-single-details',
  templateUrl: './dual-single-details.component.html',
  styleUrls: ['./dual-single-details.component.scss'],
})
export class DualSingleDetailsComponent implements OnInit {
  type = ChartType.Bar;
  chartData = [];
  columnNames = [' ', ' '];
  options = {};
  private globalVar;

  /**
   * pollutes the bar chart with the given data and sets the style. translated on each reopening
   *
   * @param dialogRef new popup window
   * @param data data for the chart
   * @param global global methods and variables
   * @param translator
   */
  constructor(@Optional() public dialogRef: MatDialogRef<DualSingleDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private global: GlobalService,
              private translator: TranslateService) {
    this.globalVar = global;
    let power;
    let gas;
    let dual;
    this.translator.get('dashboard.header.dualSingleDetails.power').subscribe((result: string) => power = result);
    this.translator.get('dashboard.header.dualSingleDetails.gas').subscribe((result: string) => gas = result);
    this.translator.get('dashboard.header.dualSingleDetails.dual').subscribe((result: string) => dual = result);

    const calendar = this.globalVar.getCalendarTranslations();

    data.productivity.forEach(item => {
      if (item.period !== 'Day') {
        this.chartData.push(
          [calendar[Calendar[item.period.toLowerCase()]] + ' ' + dual, item.dual],
          [calendar[Calendar[item.period.toLowerCase()]] + ' ' + gas, item.gas],
          [calendar[Calendar[item.period.toLowerCase()]] + ' ' + power, item.power]);
      }
    });

    this.options = {
      height: Number(this.data.height.split('p')[0]) - 150,
      legend: {position: 'none'},
      colors: [this.globalVar.rangerBlue, this.globalVar.rangerOrange, this.globalVar.rangerBlueLight],

    };
  }

  ngOnInit() {
  }

  /**
   * closes the new window
   */
  close() {
    this.dialogRef.close('Dual/single Details Closed');
  }
}
