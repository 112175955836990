import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Messages} from '../../../interfaces/messages';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {VideoDialogComponent} from '../../gadgets/video-dialog/video-dialog.component';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnChanges {
  @Input() messagesModel: Messages[];
  @Input() title: string;
  public spinning = true;
  private globalVar;
  private dialogRef: MatDialogRef<VideoDialogComponent>;

  constructor(private global: GlobalService, public dialog: MatDialog) {
    this.globalVar = global;
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.messagesModel && changes.messagesModel.currentValue) {
      this.spinning = false;
    }
  }

  /**
   * opens a modal dialog with an embedded video player
   *
   * @param videoLink url to the video
   */
  showVideo(videoLink) {
    const innerWidth = window.innerWidth;
    const dialogConfig = this.globalVar.prepareDialog(innerWidth);
    dialogConfig.data = {
      videoUrl: videoLink
    };
    this.dialogRef = this.dialog.open(VideoDialogComponent, dialogConfig);

  }
}
