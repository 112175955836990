import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponentsModule} from './components/header/header.components.module';
import {environment} from '../environments/environment';

import {GoogleChartsModule} from 'angular-google-charts';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouteReuseStrategy} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export const createTranslateLoader = (http) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HeaderComponentsModule,
    GoogleChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: 'API_URL', useValue: environment.apiUrl},
    {provide: 'ENGIEAPP_URL', useValue: environment.engieAppUrl},
    {provide: 'HERAAPP_URL', useValue: environment.heraAppUrl},
    {provide: 'HERABACKENDAPP_URL', useValue: environment.heraBackendAppUrl},
    {provide: 'SMARTAPP_URL', useValue: environment.smartAppUrl},
    {provide: 'SMARTBACKENDAPP_URL', useValue: environment.smartBackendAppUrl},
    {provide: 'EDISONAPP_URL', useValue: environment.edisonAppUrl},
    {provide: 'EDISONBACKENDAPP_URL', useValue: environment.edisonBackendAppUrl}],
  bootstrap: [AppComponent],
})
export class AppModule {
}
