import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {GoogleChartsModule} from 'angular-google-charts';
import {ChartsComponentsModule} from '../charts/charts.components.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {VideoComponent} from './video/video.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {ProductsComponent} from './products/products.component';
import {MatButtonModule} from '@angular/material/button';
import {AppsComponent} from './apps/apps.component';
import {GadgetsComponentsModule} from '../gadgets/gadgets.components.module';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';



export const createTranslateLoader = (http) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    DashboardComponent,
    VideoComponent,
    ProductsComponent,
    AppsComponent
  ],
  imports: [
    FlexModule,
    ChartsComponentsModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    IonicModule,
    GoogleChartsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatGridListModule,
    GadgetsComponentsModule,
    MatCardModule
  ],
  exports: [
    DashboardComponent,
    VideoComponent,
    ProductsComponent,
    AppsComponent
  ]
})
export class TabsComponentsModule {
}
