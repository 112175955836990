import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Productivity} from '../../../../interfaces/productivity';

@Component({
  selector: 'app-daily-goal',
  templateUrl: './daily-goal.component.html',
  styleUrls: ['./daily-goal.component.scss'],
})
export class DailyGoalComponent implements OnInit, OnChanges {

  @Input() productivity: Productivity;
  @Input() title: string;
  public time;
  public barLength = 650;
  public bells = [150,200,250,350,500];
  public goal = 100;
  public goalStyle = '--label-value:' + Math.min(100,(100 - Math.floor(this.goal / this.barLength * 100))) + '%;';
  public value: string;
  public valueStyle: string;
  public data: number;
  public spinning = true;

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   * updates the bar for the daily goal
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.productivity && changes.productivity.currentValue) {
      this.value = this.productivity.profit.toFixed(2);
      const options = {
        hour: '2-digit', minute: '2-digit'
      } as const;
      this.time = new Date().toLocaleTimeString('de-de', options);
      this.data = this.productivity.profit / this.barLength;
      this.valueStyle = '--bar-value:' + Math.min(100,(Math.floor(this.data * 100))) + '%;';
      this.spinning = false;
    }
  }
}
