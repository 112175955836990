import {Injectable} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public spinning; //Global spinner overlay
  public api: URL; // initial set as environment variable
  public headerHeight: number; // Height of the ranger banner on top of each page ; initial set in app.component.ts
  public engieAppUrl: string;
  public heraAppUrl: string;
  public heraBackendAppUrl: string;
  public smartAppUrl: string;
  public smartBackendAppUrl: string;
  public edisonAppUrl: string;
  public edisonBackendAppUrl: string;

  constructor(private translator: TranslateService) {
  }

  /**
   * top spacing for all pages based on the current size of the header
   */
  get pageStyle() {
    if (!this.headerHeight) {
      this.headerHeight = document.getElementById('globalHeader').clientHeight;
    }
    return {
      top: this.headerHeight + 'px',
    };
  }

  get currentUser(): string {
    return localStorage.getItem('currentUser');
  }

  get currentLogo(): string {
    return localStorage.getItem('logo');
  }

  get rangerOrange(): string {
    return '#ff5c10';
  }

  get rangerBlue(): string {
    return '#08204a'; // #083659
  }

  get rangerBlueLight(): string {
    return '#0f558a';
  }

  get rangerBlueShade(): string {
    return '#042036';
  }

  get rangerGrey(): string {
    return '#b0b0b0';
  }

  setHeaderHeight() {
    let round = 0;
    const checkHeaderHeight = setInterval((globalVar: GlobalService) => {
      round++;
      if (round > 10) {
        clearInterval(checkHeaderHeight);
      }

      const height = document.getElementById('globalHeader').clientHeight;
      if (height > 0) {
        globalVar.headerHeight = height;
        clearInterval(checkHeaderHeight);
      }
    }, 500, this);
  }

  /**
   * prepares the model dialog for extra statistics and videos
   *
   * @param innerWidth width of the current displayed page, to set the modal dialog size
   */
  prepareDialog(innerWidth) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    let relativeWidth; // take up to 80% of the screen size
    if (innerWidth > 1500) {
      relativeWidth = (1500 * 80) / 100;
    } else {
      relativeWidth = (innerWidth * 80) / 100;
    }

    const relativeHeight = (relativeWidth * 9) / 16 + 100; // 16:9 to which we add 120 px for the dialog action buttons ("close")
    dialogConfig.width = relativeWidth + 'px';
    dialogConfig.height = relativeHeight + 'px';
    return dialogConfig;
  }

  /**
   * returns an array with the translated months. Calendar enum can be used for matching
   */
  getCalendarTranslations() {
    const calendar = [];

    this.translator.get('period.Week').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.January').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.February').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.March').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.April').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.May').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.June').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.July').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.August').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.September').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.October').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.November').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.December').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.Month').subscribe((result: string) => calendar.push(result));
    this.translator.get('period.Day').subscribe((result: string) => calendar.push(result));

    return calendar;
  }
}
