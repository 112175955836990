import {Injectable, SecurityContext} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalService} from './global.service';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  private globalVar: GlobalService;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private sanitizer: DomSanitizer) {
    this.globalVar = globalService;
  }

  /**
   * get request using the bearer token
   *
   * @param path path of the api url
   */
  apiGetRequest(path: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        authorization: 'Bearer ' + this.globalVar.currentUser
      }),
    };

    const url = this.globalVar.api.href + path;

    //http get returns dashboard data in subscription
    return this.http.get(url, httpOptions);
  }

  /**
   * post request sending data
   *
   * @param path path of the api url
   * @param postData login credentials
   */
  apiPostRequest(path: string, postData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
      }),
    };

    const url = this.globalVar.api.href + path;

    return this.http.post(url, postData, httpOptions);
  }

  /**
   * gets an user image as base64 using the bearer token
   *
   * @param path path of the api url
   */
  apiGetImageRequest(path: string) {
    const options: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        authorization: 'Bearer ' + this.globalVar.currentUser
      }),
      responseType: 'blob'
    };

    const url = this.globalVar.api.href + path;

    //http get returns dashboard data in subscription
    return this.http.get(url, options);
  }

  createImageFromBlob(image: Blob) {
    const objectURL = URL.createObjectURL(image);
    return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(objectURL));
  }
}
