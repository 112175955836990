import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Productivity} from '../../../../interfaces/productivity';
import {Calendar} from '../../../../enums/calendar';
import {GlobalService} from '../../../../services/global.service';

@Component({
  selector: 'app-goal-bar',
  templateUrl: './goal-bar.component.html',
  styleUrls: ['./goal-bar.component.scss'],
})
export class GoalBarComponent implements OnInit, OnChanges {

  @Input() productivity: Productivity;
  @Input() title: string;

  goal: number;
  goalStyle: string;
  value: string;
  valueStyle: string;
  forecast;
  forecastStyle: string;
  public spinning = true;
  private globalVar;

  constructor(private global: GlobalService) {
    this.globalVar = global;
  }

  ngOnInit() {
  }

  /**
   * called when the data is available. sets the weekly and monthly goal bars and starts the calculation for the forecasts
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.productivity && changes.productivity.currentValue) {
      this.value = this.productivity.profit.toFixed(2);
      let barLength: number;
      if (this.productivity.dateType === Calendar.week) {
        barLength = 650;
        this.goal = 500;
        this.forecast = (this.calcWeekForecast()).toFixed(2);
      } else {
        barLength = 2300;
        this.goal = 1800;
        this.forecast = (this.calcMonthForecast()).toFixed(2);
      }
      // math.min takes the lower value so we have a max of 100%, Math.floor rounds down to int
      // *100 to get % and for goals 100-value because the align comes from right and we need it inverted
      this.goalStyle = '--label-value:' + Math.min(100,(100 - Math.floor(this.goal / barLength * 100))) + '%;';
      this.valueStyle = '--bar-value:' + Math.min(100,(Math.floor(this.productivity.profit / barLength * 100))) + '%;';
      this.forecastStyle = '--bar-value:' + Math.min(100,(Math.floor(this.forecast / barLength * 100))) + '%;';
      this.spinning = false;
    }
  }

  /**
   * checks if the date is a business day
   *
   * @param year
   * @param month
   * @param day
   */
  isWeekday(year, month, day) {
    const weekDay = new Date(year, month, day).getDay();
    return weekDay !== 0 && weekDay !== 6;
  }

  /**
   * number of days for the given month
   *
   * @param month
   * @param year
   */
  daysInMonth(month, year) {
    return 32 - new Date(year, month, 32).getDate();
  }

  /**
   * number of business days in given month
   *
   * @param month
   * @param year
   */
  getWeekdaysInMonth(month, year) {
    const days = this.daysInMonth(month, year);
    let weekdays = 0;
    for (let i = 0; i < days; i++) {
      if (this.isWeekday(year, month, i + 1)) {
        weekdays++;
      }
    }
    return weekdays;
  }

  /**
   * calculating the week forecast based on the earnings so far
   */
  calcWeekForecast() {
    const pastDays = new Date().getDay();
    const daysLeft = 6 - pastDays;
    return this.productivity.profit + (this.productivity.profit / pastDays * daysLeft);
  }

  /**
   * calculates the month forecast based on the business days for this month and the earnings so far
   */
  calcMonthForecast() {
    const currentDate = new Date();
    const pastDays = currentDate.getUTCDate();
    const pastWeekends = pastDays / 7 + (pastDays % 7 > 0 ? 2 : 0);
    const pastBusinessDays = pastDays - pastWeekends > 0 ? pastDays - pastWeekends : 1;
    const daysLeft = this.getWeekdaysInMonth(currentDate.getMonth(), currentDate.getFullYear()) - pastBusinessDays;
    return this.productivity.profit + (this.productivity.profit / pastBusinessDays * daysLeft);
  }
}
