import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Anomalies} from '../../../interfaces/anomalies';

@Component({
  selector: 'app-anomalies',
  templateUrl: './anomalies.component.html',
  styleUrls: ['./anomalies.component.scss'],
})
export class AnomaliesComponent implements OnInit, OnChanges {
  @Input() anomalies: Anomalies;
  @Input() title: string;
  public spinning = true;

  constructor() { }

  ngOnInit() {}

  /**
   * called when the data is available
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.anomalies && changes.anomalies.currentValue) {
      this.spinning = false;
    }
  }

  /**
   * opens ranger world in new window
   */
  openLink(){
    const url='https://crm.dea.group/World/Login.wc';
    window.open(url, '_blank');
  }
}
