import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../../../services/global.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() office: string;
  public username;
  public globalVar;

  constructor(private globalService: GlobalService) {
    this.globalVar = globalService;
    this.username = localStorage.getItem('username');
  }

  ngOnInit() {
  }

}
