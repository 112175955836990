import {AgentAndGroupLevel} from '../../../../interfaces/agent-and-group-level';
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-tier',
  templateUrl: './tier.component.html',
  styleUrls: ['./tier.component.scss'],
})
export class TierComponent implements OnInit {
  @Input() agentAndGroupLevel: AgentAndGroupLevel[];
  public tiers: AgentAndGroupLevel[] = [];
  public tierUnSelected: { src: string; text: string };


  constructor() {
    this.tierUnSelected = {src: '../../../../assets/img/tier/tier-unselected.svg', text: ''};
  }

  /**
   * pollutes the tiers dynamically and highlights the selected
   */
  ngOnInit() {
    if(this.agentAndGroupLevel) {
      this.agentAndGroupLevel.forEach((item) => {
        const tier: AgentAndGroupLevel = {
          isAgent: item.isAgent,
          tier: item.tier.replace(/RANGER OFFICE/g, '') //Removes "RANGER OFFICE" from Tiers
            .replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()) // Sets tier to PascalCase
        };
        this.tiers.push(tier);
      });
      this.tiers.reverse();
    }
  }
}
