import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {LanguageSelectorComponent} from './language-selector/language-selector.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {GoogleChartsModule} from 'angular-google-charts';
import {TabMenuComponent} from './tab-menu/tab-menu.component';
import {TabsComponentsModule} from '../tabs/tabs.components.module';
import {MatTabsModule} from '@angular/material/tabs';


export const createTranslateLoader = (http) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    LanguageSelectorComponent,
    TabMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    GoogleChartsModule,
    TabsComponentsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatTabsModule
  ],
  exports: [
    LanguageSelectorComponent,
    TabMenuComponent
  ]
})
export class HeaderComponentsModule {
}
