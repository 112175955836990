import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ChartType} from 'angular-google-charts';
import {GlobalService} from '../../../../services/global.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DualSingleDetailsComponent} from '../../bar-charts/dual-single-details/dual-single-details.component';
import {Productivity} from '../../../../interfaces/productivity';

@Component({
  selector: 'app-dual-single-pie',
  templateUrl: './dual-single-pie.component.html',
  styleUrls: ['./dual-single-pie.component.scss'],
})
export class DualSinglePieComponent implements OnInit, OnChanges {
  @Input() productivity: Productivity[];
  @Input() title: string;
  type = ChartType.PieChart;
  data = [];
  columnNames = ['Type', 'Sales'];
  options = {};
  public spinning = true;
  private globalVar;
  private dialogRef: MatDialogRef<DualSingleDetailsComponent>;

  constructor(private global: GlobalService,
              public dialog: MatDialog,
              private translator: TranslateService) {
    this.globalVar = global;
  }

  ngOnInit() {
  }

  /**
   * called when the data is available
   * updates the chart and sets the design. differs if all data is 0
   *
   * @param changes changed data
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.productivity && changes.productivity.currentValue) {
      let power;
      let gas;
      let dual;
      this.translator.get('dashboard.header.dualSingleDetails.power').subscribe((result: string) => power = result);
      this.translator.get('dashboard.header.dualSingleDetails.gas').subscribe((result: string) => gas = result);
      this.translator.get('dashboard.header.dualSingleDetails.dual').subscribe((result: string) => dual = result);
      const day = this.productivity.find(item => item.period === 'Day');
      if (day.dual === 0 && day.power === 0 && day.gas === 0) {
        let start;
        this.translator.get('dashboard.pieChart.start').subscribe((result: string) => start = result);
        this.data = ([[start, 1]]);
        this.options = {
          legend: {position: 'none'},
          chartArea: {width: '80%', height: '80%'},
          pieSliceText: 'label',
          axisFontSize: 0,
          slices: {
            0: {color: this.globalVar.rangerGrey}
          }
        };
      } else {
        this.data = ([[dual, day.dual], [power, day.power], [gas, day.gas]]);
        this.options = {
          legend: {
            position: 'right'
          },
          chartArea: {width: '80%', height: '80%'},
          pieSliceText: 'value',
          axisFontSize: 0,
          slices: {
            0: {color: this.globalVar.rangerOrange},
            1: {color: this.globalVar.rangerBlue},
            2: {color: this.globalVar.rangerBlueLight}
          }
        };
      }
      this.spinning = false;
    }
  }

  /**
   * opens a modal dialog to display more data in a bar chart
   *
   * @param productivity more statistic data
   */
  showDetails(productivity) {
    if (!productivity) return;
    let titleTranslated;
    this.translator.get('dashboard.header.dualSingleDetails.title').subscribe((result: string) => titleTranslated = result);

    const innerWidth = window.innerWidth;
    const dialogConfig = this.globalVar.prepareDialog(innerWidth);
    dialogConfig.data = {
      productivity,
      title: titleTranslated,
      height: dialogConfig.height,
      axes: {
        y: {
          all: {
            range: {
              min: 0
            }
          }
        }
      }
    };
    this.dialogRef = this.dialog.open(DualSingleDetailsComponent, dialogConfig);
  }
}
