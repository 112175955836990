import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-app-widget',
  templateUrl: './app-widget.component.html',
  styleUrls: ['./app-widget.component.scss'],
})
export class AppWidgetComponent implements OnInit {
  @Input() name: string;
  @Input() img: string;
  @Input() path: string;

  public globalVar;

  constructor(private globalService: GlobalService) {
    this.globalVar = this.globalService;
  }

  ngOnInit() {
  }

  onAppClicked() {
    if ((this.path.toLowerCase().includes("smartenergy") || this.path.toLowerCase().includes("edison") || this.path.toLowerCase().includes("hera")) && !this.path.toLowerCase().includes("verify")) {
      window.open(this.path + '#' + this.globalVar.currentUser, '_blank');
    }else{
      window.open(this.path + '?token=' + this.globalVar.currentUser, '_blank');
    }
  }
}
