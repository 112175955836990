import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {Token} from '../../../interfaces/token';
import {GlobalService} from '../../../services/global.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TabMenuComponent implements OnInit {
  public videoUrl;
  public productUrl;
  public selectedTab = 0;
  public seeDashboard = false;
  private lastSelected = 0;
  private globalVar: GlobalService;

  constructor(private globalService: GlobalService) {
    this.globalVar = this.globalService;
  }

  ngOnInit() {
    const token = jwt_decode(this.globalVar.currentUser) as Token;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if(token['role'].includes('Admin') || token['role'].includes('Dashboard')){
      this.seeDashboard = true;
    }
  }


  /**
   * opens external links for some tabs and then switches to last selected
   *
   * @param event event given on tab switch
   */
  onTabChange(event) {
    this.videoUrl = localStorage.getItem('videoUrl');
    this.productUrl = localStorage.getItem('productUrl');
    switch (event.tab.ariaLabel) {
      case 'academy':
        window.open(localStorage.getItem('academyUrl'), '_blank');
        this.selectedTab = this.lastSelected;
        break;
      case 'rangerWorld':
        const url = 'https://crm.dea.group/World/Login.wc';
        window.open(url, '_blank');
        this.selectedTab = this.lastSelected;
        break;
      default:
        this.lastSelected = event.index;
        break;
    }
  }
}
