import {Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
//interfaces
import {UserModel} from '../../../interfaces/user-model';
import {Recruiting} from '../../../interfaces/recruiting';
import {Messages} from '../../../interfaces/messages';
import {Productivity} from '../../../interfaces/productivity';
import {Anomalies} from '../../../interfaces/anomalies';
//services
import {GlobalService} from '../../../services/global.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ApiRequestService} from '../../../services/api-request.service';
import {AlertService} from '../../../services/alert.service';
//enums
import {UserRank} from '../../../enums/user-rank';
import {Calendar} from '../../../enums/calendar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  public globalVar;
  public user: UserModel;
  public recruiting: Recruiting[];
  public productivity: Productivity[];
  public productivityDay: Productivity;
  public productivityWeek: Productivity;
  public productivityMonth: Productivity;
  public anomalies: Anomalies;
  public messages: Messages[];
  public userImage;
  public tierImage;
  public xlStyle;
  public largeStyle;
  public mediumStyle;
  public smallStyle;
  public goalHeader;
  public weekHeader;
  public monthHeader;
  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;

  constructor(
    private navCtrl: NavController,
    private http: HttpClient,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private apiRequestService: ApiRequestService,
    private alert: AlertService,
    private sanitizer: DomSanitizer,
    private translator: TranslateService
  ) {
    this.setColSize();
    this.globalVar = this.globalService;
    this.resizeObservable = fromEvent(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(() => {
      this.setColSize();
    });
    this.updateHeaders();
  }

  ngOnInit() {
    this.getUser();
    this.getProfilePicture();
    this.getRecruiting();
    this.getProductivity();
    this.getAnomalies();
    this.getMessages();
  }

  /**
   * gets basic user info and sets the tier for site displays
   */
  getUser() {
    this.globalVar.spinning = true;
    this.apiRequestService.apiGetRequest('User').subscribe(
      (data) => {
        //token stored in local storage
        this.user = data as UserModel;
        this.tierImage = '../../../../assets/img/blank.png';
        this.user.agentAndGroupLevels.forEach(level => {
          if (level.isAgent) {
            this.tierImage = '../../../../assets/img/tier/bronze.png';
            if (level.tier.toLowerCase().includes('trainer')) {
              localStorage.setItem('userRank', '' + UserRank.trainer);
              this.tierImage = '../../../../assets/img/tier/silver.png';
            } else if (level.tier.toLowerCase().includes('manager')) {
              localStorage.setItem('userRank', '' + UserRank.manager);
              this.tierImage = '../../../../assets/img/tier/gold.png';
            } else if (level.tier.toLowerCase().includes('junior')) {
              localStorage.setItem('userRank', '' + UserRank.junior);
              this.tierImage = '../../../../assets/img/tier/blue.png';
            }
          }
        });
        console.log('User data received');
        this.updateHeaders();
        localStorage.setItem('videoUrl', '' + this.user.videoUrl);
        localStorage.setItem('academyUrl', '' + this.user.formationUrl);
        localStorage.setItem('productUrl', '' + this.user.modulisticUrl);
        this.globalVar.spinning = false;
      }, (error) => {
        this.alert.showError(error);
        console.log(error);
        this.globalVar.spinning = false;
      }
    );
  }

  /**
   * gets the profile picture
   */
  getProfilePicture() {
    this.apiRequestService.apiGetRequest('user/picture')
      .subscribe(data => {
        this.userImage = (data as { image: string }).image;
        if (this.userImage === '') {
          this.userImage = '../../../assets/img/user.png';
        } else {
          this.userImage = 'data:image/jpeg;base64,' + this.userImage;
        }
        console.log('User image received');
      }, (error) => {
        this.alert.showError(error);
        console.log(error);
      });
  }

  /**
   * gets data for the pie and bar charts
   */
  getProductivity() {
    this.apiRequestService.apiGetRequest('dashboard/productivity').subscribe((data) => {
      this.productivity = data as Productivity[];
      this.productivity.forEach(item => {
        item.dateType = Calendar[item.period.toLowerCase()];
        switch (item.dateType) {
          case Calendar.day:
            this.productivityDay = item;
            break;
          case Calendar.week:
            this.productivityWeek = item;
            break;
          default:
            this.productivityMonth = item;
            break;
        }
      });
      console.log('Productivity Data received');
    }, (error) => {
      this.alert.showError(error);
      console.log(error);
    });
  }

  /**
   * gets the recruiting statistic data
   */
  getRecruiting() {
    this.apiRequestService.apiGetRequest('dashboard/recruiting').subscribe((data) => {
      this.recruiting = data as Recruiting[];
      console.log('Recruiting Data received');
    }, (error) => {
      this.alert.showError(error);
      console.log(error);
    });
  }

  /**
   * gets the anomalies statistic data
   */
  getAnomalies() {
    this.apiRequestService.apiGetRequest('dashboard/anomalies').subscribe((data) => {
      this.anomalies = data as Anomalies;
      console.log('Anomalies Data received');
    }, (error) => {
      this.alert.showError(error);
      console.log(error);
    });
  }

  /**
   * gets messages to display
   */
  getMessages() {
    this.apiRequestService.apiGetRequest('dashboard/messages').subscribe((data) => {
      this.messages = data as Messages[];
      this.messages.forEach(message => {
        if (message.date && message.date.toString() !== ''
          && message.header && message.header !== ''
          && message.text && message.text !== '') {
          const newDate = message.date.toString().split('-');
          message.date = new Date(Number(newDate[2]), Number(newDate[1]) - 1, Number(newDate[0]));
          if (message.isHtml) {
            message.html = this.sanitizer.bypassSecurityTrustHtml(message.text);
          }
        }else{
          message.date = new Date();
          let empty: string;
          this.translator.get('dashboard.emptyMessages').subscribe((result: string) => empty = result);
          message.header = empty;
        }
      });
      console.log('messages received');
    }, (error) => {
      this.alert.showError(error);
      console.log(error);
    });
  }

  /**
   * updates the size of the columns while resizing
   */
  setColSize() {
    const size = window.screen.availWidth;
    if (size < 401) {
      this.xlStyle = '--xl-value:' + (size - 30) + 'px;';
      this.largeStyle = '--large-value:' + (size - 30) + 'px;';
      this.mediumStyle = '--medium-value:' + (size - 30) + 'px;';
      this.smallStyle = '--small-value:' + (size - 30) + 'px;';
    } else if (size < 740) {
      this.xlStyle = '--xl-value:' + (size - 15) + 'px;';
      this.largeStyle = '--large-value:' + 400 + 'px;';
      this.mediumStyle = '--medium-value:' + ((size / 2) - 15) + 'px;';
      this.smallStyle = '--small-value:' + 150 + 'px;';
    } else if (size < 1100) {
      this.xlStyle = '--xl-value:' + (size - 10) + 'px;';
      this.largeStyle = '--large-value:' + ((size / 3) - 8) + 'px;';
      this.mediumStyle = '--medium-value:' + ((size / 2) - 5) + 'px;';
      this.smallStyle = '--small-value:' + ((size / 8) - 3) + 'px;';
    } else {
      this.xlStyle = '--xl-value:' + 500 + 'px;';
      this.largeStyle = '--large-value:' + 400 + 'px;';
      this.mediumStyle = '--medium-value:' + 250 + 'px;';
      this.smallStyle = '--small-value:' + 200 + 'px;';
    }
  }

  /**
   * updates the headers based on the user rank
   */
  updateHeaders() {
    const rank = localStorage.getItem('userRank');
    switch (rank) {
      case UserRank.trainer.toString():
        this.goalHeader = 'dashboard.header.dailyGoal.trainer';
        this.weekHeader = 'dashboard.header.weeklyGoal.trainer';
        this.monthHeader = 'dashboard.header.monthlyGoal.trainer';
        break;
      case UserRank.manager.toString():
        this.goalHeader = 'dashboard.header.dailyGoal.manager';
        this.weekHeader = 'dashboard.header.weeklyGoal.manager';
        this.monthHeader = 'dashboard.header.monthlyGoal.manager';
        break;
      default:
        this.goalHeader = 'dashboard.header.dailyGoal.guy';
        this.weekHeader = 'dashboard.header.weeklyGoal.guy';
        this.monthHeader = 'dashboard.header.monthlyGoal.guy';
        break;
    }
  }
}
